import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import inwardService from './inwardService';

const initialState = {
  vendors: null,
  wmsSetup: null,
  items: null,
  inward: null,
  inwardList: null,
  stn: null,
  drafts: null,
  logs: null,
  vendorAddress: null,
  invoices: null,
  lot: null,
  locations: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: ''
};

export const fetchPoVendorList = createAsyncThunk('vendor/get_all', async (data, thunkAPI) => {
  try {
    return await inwardService.fetchPoVendorList(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchWmsSetup = createAsyncThunk('client_setup/process_type_detail', async (data, thunkAPI) => {
  try {
    return await inwardService.fetchWmsSetup(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchAllItems = createAsyncThunk('item/all_item_list', async (data, thunkAPI) => {
  try {
    return await inwardService.fetchAllItems(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createPo = createAsyncThunk('transaction/create_po', async (poData, thunkAPI) => {
  try {
    return await inwardService.createPo(poData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchPoList = createAsyncThunk('transaction/list_all_po', async ({ pageNum, data }, thunkAPI) => {
  try {
    return await inwardService.fetchPoList(pageNum, data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchPoDetail = createAsyncThunk('common/po_detail', async (data, thunkAPI) => {
  try {
    return await inwardService.fetchPoDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const poConfirmation = createAsyncThunk('common/po_confirmation', async (data, thunkAPI) => {
  try {
    return await inwardService.poConfirmation(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updatePo = createAsyncThunk('transaction/edit_po', async (data, thunkAPI) => {
  try {
    return await inwardService.updatePo(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createDraft = createAsyncThunk('common/create_draft', async (draftData, thunkAPI) => {
  try {
    return await inwardService.createDraft(draftData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchDraftList = createAsyncThunk('common/draft_list', async ({ pageNum, organizationId }, thunkAPI) => {
  try {
    return await inwardService.fetchDraftList(pageNum, organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchDraftDetail = createAsyncThunk('common/get_draft_details', async (data, thunkAPI) => {
  try {
    return await inwardService.fetchDraftDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateDraft = createAsyncThunk('common/update_draft', async (data, thunkAPI) => {
  try {
    return await inwardService.updateDraft(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteDraft = createAsyncThunk('common/delete_draft', async (data, thunkAPI) => {
  try {
    return await inwardService.deleteDraft(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchPoLogList = createAsyncThunk('logs/order_logs_list', async ({ pageNum, organizationId, logData }, thunkAPI) => {
  try {
    return await inwardService.fetchPoLogList(pageNum, organizationId, logData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchPoVendorAddressList = createAsyncThunk('vendor/po_vendor_address_list', async (data, thunkAPI) => {
  try {
    return await inwardService.fetchPoVendorAddressList(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createPoInvoice = createAsyncThunk('transaction/create_invoice', async (invoiceData, thunkAPI) => {
  try {
    return await inwardService.createPoInvoice(invoiceData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchInvoiceList = createAsyncThunk('transaction/invoice_list', async ({ pageNum, data }, thunkAPI) => {
  try {
    return await inwardService.fetchInvoiceList(pageNum, data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchInvoiceDetail = createAsyncThunk('transaction/invoice_detail', async (data, thunkAPI) => {
  try {
    return await inwardService.fetchInvoiceDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateInvoice = createAsyncThunk('common/edit_invoice', async (data, thunkAPI) => {
  try {
    return await inwardService.updateInvoice(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchPoInvoiceList = createAsyncThunk('transaction/po_invoice_list', async (data, thunkAPI) => {
  try {
    return await inwardService.fetchPoInvoiceList(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchPoInvoiceDetail = createAsyncThunk('transaction/po_invoice_detail', async (data, thunkAPI) => {
  try {
    return await inwardService.fetchPoInvoiceDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchPoInvoiceLotDetail = createAsyncThunk('transaction/po_invoice_lot_detail', async (data, thunkAPI) => {
  try {
    return await inwardService.fetchPoInvoiceLotDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const invoiceMismatchProcess = createAsyncThunk('transaction/invoice_mismatch_process', async (data, thunkAPI) => {
  try {
    return await inwardService.invoiceMismatchProcess(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const grnCompleteFromExcel = createAsyncThunk('transaction/grn_complete_from_excel', async (data, thunkAPI) => {
  try {
    return await inwardService.grnCompleteFromExcel(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchStockTransferItemDetail = createAsyncThunk('transaction/stock_transfer_item_detail', async (data, thunkAPI) => {
  try {
    return await inwardService.fetchStockTransferItemDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createStn = createAsyncThunk('transaction/create_stn', async (data, thunkAPI) => {
  try {
    return await inwardService.createStn(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchReceiveList = createAsyncThunk(
  'transaction/receiving_list',
  async ({ pageNum, organizationId, warehouseId }, thunkAPI) => {
    try {
      return await inwardService.fetchReceiveList(pageNum, organizationId, warehouseId);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchCreateLotDetail = createAsyncThunk('transaction/create_lot_detail', async (data, thunkAPI) => {
  try {
    return await inwardService.fetchCreateLotDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const lotReceiving = createAsyncThunk('transaction/lot_receiving', async (data, thunkAPI) => {
  try {
    return await inwardService.lotReceiving(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchLotList = createAsyncThunk(
  'transaction/lot_list',
  async ({ pageNum, organizationId, warehouseId, lotStage }, thunkAPI) => {
    try {
      return await inwardService.fetchLotList(pageNum, organizationId, warehouseId, lotStage);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchReceiveLotDetail = createAsyncThunk('transaction/receive_lot_detail', async (data, thunkAPI) => {
  try {
    return await inwardService.fetchReceiveLotDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createQc = createAsyncThunk('transaction/create_qc', async (data, thunkAPI) => {
  try {
    return await inwardService.createQc(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchPutAwayLotDetail = createAsyncThunk('transaction/putaway_lot_detail', async (data, thunkAPI) => {
  try {
    return await inwardService.fetchPutAwayLotDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchBinLocations = createAsyncThunk('transaction/get_bin_location', async (data, thunkAPI) => {
  try {
    return await inwardService.fetchBinLocations(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createPutaway = createAsyncThunk('transaction/create_putaway', async (data, thunkAPI) => {
  try {
    return await inwardService.createPutaway(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const cancelLot = createAsyncThunk('transaction/cancel_lot', async (data, thunkAPI) => {
  try {
    return await inwardService.cancelLot(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const completeGrn = createAsyncThunk('transaction/complete_grn', async (data, thunkAPI) => {
  try {
    return await inwardService.completeGrn(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchStnOutOrderDetail = createAsyncThunk('transaction/stn_out_order_detail', async (data, thunkAPI) => {
  try {
    return await inwardService.fetchStnOutOrderDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createDi = createAsyncThunk('transaction/create_direct_invoice', async (data, thunkAPI) => {
  try {
    return await inwardService.createDi(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const inwardSlice = createSlice({
  name: 'inward',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPoVendorList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPoVendorList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.vendors = action.payload;
      })
      .addCase(fetchPoVendorList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchWmsSetup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWmsSetup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.wmsSetup = action.payload;
      })
      .addCase(fetchWmsSetup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchAllItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.items = action.payload;
      })
      .addCase(fetchAllItems.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createPo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.inward = action.payload;
      })
      .addCase(createPo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchPoList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPoList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.inwardList = action.payload;
      })
      .addCase(fetchPoList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchPoDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPoDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.inward = action.payload;
      })
      .addCase(fetchPoDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(poConfirmation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(poConfirmation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.inward = action.payload;
      })
      .addCase(poConfirmation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updatePo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.inward = action.payload;
      })
      .addCase(updatePo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createDraft.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createDraft.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.drafts = action.payload;
      })
      .addCase(createDraft.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchDraftList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDraftList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.drafts = action.payload;
      })
      .addCase(fetchDraftList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchDraftDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDraftDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.drafts = action.payload;
      })
      .addCase(fetchDraftDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateDraft.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDraft.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.drafts = action.payload;
      })
      .addCase(updateDraft.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteDraft.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDraft.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.drafts = action.payload;
      })
      .addCase(deleteDraft.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchPoLogList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPoLogList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.logs = action.payload;
      })
      .addCase(fetchPoLogList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchPoVendorAddressList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPoVendorAddressList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.vendorAddress = action.payload;
      })
      .addCase(fetchPoVendorAddressList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createPoInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPoInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.invoices = action.payload;
      })
      .addCase(createPoInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchInvoiceList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchInvoiceList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.invoices = action.payload;
      })
      .addCase(fetchInvoiceList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchInvoiceDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchInvoiceDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.invoices = action.payload;
      })
      .addCase(fetchInvoiceDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.invoices = action.payload;
      })
      .addCase(updateInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchPoInvoiceList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPoInvoiceList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.invoices = action.payload;
      })
      .addCase(fetchPoInvoiceList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchPoInvoiceDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPoInvoiceDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.invoices = action.payload;
      })
      .addCase(fetchPoInvoiceDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchPoInvoiceLotDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPoInvoiceLotDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.invoices = action.payload;
      })
      .addCase(fetchPoInvoiceLotDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(invoiceMismatchProcess.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(invoiceMismatchProcess.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.invoices = action.payload;
      })
      .addCase(invoiceMismatchProcess.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(grnCompleteFromExcel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(grnCompleteFromExcel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lot = action.payload;
      })
      .addCase(grnCompleteFromExcel.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchStockTransferItemDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchStockTransferItemDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.items = action.payload;
      })
      .addCase(fetchStockTransferItemDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createStn.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createStn.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.stn = action.payload;
      })
      .addCase(createStn.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchReceiveList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchReceiveList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lot = action.payload;
      })
      .addCase(fetchReceiveList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchCreateLotDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCreateLotDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lot = action.payload;
      })
      .addCase(fetchCreateLotDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(lotReceiving.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(lotReceiving.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lot = action.payload;
      })
      .addCase(lotReceiving.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchLotList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLotList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lot = action.payload;
      })
      .addCase(fetchLotList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchReceiveLotDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchReceiveLotDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lot = action.payload;
      })
      .addCase(fetchReceiveLotDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createQc.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createQc.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lot = action.payload;
      })
      .addCase(createQc.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchPutAwayLotDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPutAwayLotDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lot = action.payload;
      })
      .addCase(fetchPutAwayLotDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchBinLocations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBinLocations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.locations = action.payload;
      })
      .addCase(fetchBinLocations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createPutaway.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPutaway.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lot = action.payload;
      })
      .addCase(createPutaway.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(cancelLot.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cancelLot.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lot = action.payload;
      })
      .addCase(cancelLot.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(completeGrn.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(completeGrn.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lot = action.payload;
      })
      .addCase(completeGrn.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchStnOutOrderDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchStnOutOrderDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.inward = action.payload;
      })
      .addCase(fetchStnOutOrderDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createDi.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createDi.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.inward = action.payload;
      })
      .addCase(createDi.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset } = inwardSlice.actions;
export default inwardSlice.reducer;
