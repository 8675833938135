import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, List, Typography } from '@mui/material';
import NavItem from './NavItem';
import NavCollapse from './NavCollapse';
import { ControlOutlined, FileProtectOutlined, HomeOutlined, QrcodeOutlined, RetweetOutlined, SettingOutlined } from '@ant-design/icons';
import { useMemo, useState } from 'react';

const NavGroup = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  const getIcon = (title) => {
    const icons = {
      Home: <HomeOutlined style={{ fontSize: '1.1rem' }} />,
      Transactions: <RetweetOutlined style={{ fontSize: '1.1rem' }} />,
      Master: <QrcodeOutlined style={{ fontSize: '1.1rem' }} />,
      Reports: <FileProtectOutlined style={{ fontSize: '1.1rem' }} />,
      Setups: <ControlOutlined style={{ fontSize: '1.1rem' }} />,
      General: <SettingOutlined style={{ fontSize: '1.1rem' }} />
    };
    return icons[title] || null;
  };

  const icon = useMemo(() => getIcon(item?.title?.props?.id), [item?.title?.props?.id]);

  const navCollapse = item.children?.map((menuItem) => {
    switch (menuItem.type) {
      case 'collapse':
        return <NavCollapse key={menuItem.id} menu={menuItem} level={1} />;
      case 'item':
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return (
          <Typography key={menuItem.id} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  return (
    <List sx={{ m: 0, p: 0 }}>
      {item.title && drawerOpen ? (
        <Box sx={{ m: 0, p: 0 }}>
          <Box
            onClick={() => setIsOpen(!isOpen)}
            sx={{
              cursor: 'pointer',
              backgroundColor: '#fff',
              p: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              '&:hover': {
                backgroundColor: '#f5f5f5'
              }
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body1" color="#262626">
                {item.title}
              </Typography>
            </Box>
            {icon}
          </Box>
          {isOpen && (
            <Box
              sx={{
                backgroundColor: 'transparent',
                display: 'block'
              }}
            >
              {navCollapse}
            </Box>
          )}
        </Box>
      ) : (
        navCollapse
      )}
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object
};

export default NavGroup;
